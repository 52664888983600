<template>
	<v-container fill-height fixed>
		<v-layout justify-center wrap>
			<material-card color="info" title="Привязка">
				<v-container>
					<v-layout wrap>
						<v-flex xs12>
							<v-text-field
								:disabled="success"
								:loading="loading"
								label="Код"
								max="5"
								outlined
								v-model="pin"
								:error-messages="error"
							></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-alert type="success" v-if="success">Привязка произведена успешно</v-alert>
						</v-flex>
					</v-layout>
				</v-container>
			</material-card>
			<material-card color="info" title="Справка">
			<v-layout justify-center>
				<b>Бот для Telegram</b>
			</v-layout>
			Чтобы включить бота найдите в поиске телеграма @AtotoruBot или перейдите по <a href="https://t.me/atotorubot" target="_blank">ссылке</a>
			</material-card>
		</v-layout>
	</v-container>
</template>

<script>
import { Helper } from "../../mixins/Helper";

export default {
	mixins: [Helper],
	data() {
		return {
			loading: false,
			error: null,
			pin: null,
			success: false
		};
	},
	created() {
		document.title = "Привязка устройств";
	},
	watch: {
		pin: function(val) {
			if (val.length == 5) this.checkPin();
		}
	},
	methods: {
		checkPin() {
			let t = this;
			t.loading = true;
			window.ajax("/api/pin/" + t.pin, {}, function(data) {
				t.loading = false;
				if (!data.error) {
					t.success = true;
				} else {
					t.error = data.error;
				}
			});
		}
	}
};
</script>
<style scoped>
</style>